<template>
    <v-card outlined>
        <v-card-title class="pl-8 block-title">인사말 <span style="margin-left: 10px; font-size: 17px; font-weight: normal; color: #919191;">(전화한 고객에게 하는 인사 멘트)</span></v-card-title>
        <v-card-text class="mt-3 mb-n8">
            <v-row>
                <v-col>
                    <v-textarea outlined placeholder="인사말을 입력해 주세요" v-model="introMent"></v-textarea>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'svc_voice_intro_intro_ment',
    components: {},
    data: () => ({
        introMent: 'HELLO!!!',
        debounceTimer: undefined
    }),
    computed: {
        ...mapState('svcVoiceIntro', {
            stateIntroMent: state => state.introMent
        })
    },
    watch: {
        introMent() {
            this.storeMent()
        }
    },
    created() {
    },
    mounted: function () {
        this.reload()
    },
    methods: {
        reload() {
            this.introMent = this.stateIntroMent
        },
        apply() {
            this.$store.commit('svcVoiceIntro/setIntroMent', this.introMent)
        },
        storeMent() {
            if (this.debounceTimer) {
                clearTimeout(this.debounceTimer)
            }

            this.debounceTimer = setTimeout(() => {
                this.$store.commit('svcVoiceIntro/setIntroMent', this.introMent)
            }, 200)
        }
    }
}
</script>

